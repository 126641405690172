import React from 'react';
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function LandReclamationService() {
  return (
    <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
      <Col span={24}>
        <Title level={3} className='section-title'>Land Reclamation Service</Title>
        <Divider />
        <Paragraph style={{marginBottom: "0"}}>
          At BSKLandscapes, we specialize in land reclamation, transforming neglected or underutilized land into functional and sustainable spaces. Our land reclamation service focuses on restoring and enhancing the natural beauty of the land while considering environmental conservation and ecological balance.
        </Paragraph>
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Site Assessment and Planning</Title>
            </div>
            <Paragraph>
              Our experienced team conducts thorough site assessments to understand the unique characteristics and challenges of the land. We develop comprehensive reclamation plans that consider factors such as soil condition, water management, and vegetation restoration. Our goal is to create a sustainable and functional landscape that aligns with your vision and the natural environment.
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Erosion Control and Stabilization</Title>
            </div>
            <Paragraph>
              We employ erosion control and stabilization techniques to prevent soil erosion and promote long-term stability. Through the use of retaining walls, terracing, soil stabilization methods, and appropriate vegetation selection, we ensure that the reclaimed land remains secure and resilient against erosion and environmental factors.
            </Paragraph>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Native Plant Restoration</Title>
            </div>
            <Paragraph>
              We prioritize the restoration of native plant species to enhance biodiversity and ecological balance in the reclaimed area. Our team carefully selects and cultivates native plants that are well-suited to the local climate and ecosystem. By reintroducing native vegetation, we contribute to the preservation of local wildlife habitats and create a sustainable and natural landscape.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          Our land reclamation service goes beyond mere restoration. We aim to create a harmonious blend of ecological integrity, functional design, and aesthetic appeal. With our expertise and commitment to sustainability, we can reclaim and transform land into a valuable asset that benefits both the environment and the community.
        </Paragraph>
        <Paragraph>
          Contact us today to discuss your land reclamation project and let us help you restore and rejuvenate the beauty and potential of your land.
        </Paragraph>
      </Col>
    </Row>
  );
}

export default LandReclamationService;