import React from 'react';
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function FencingService() {
  return (
    <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
      <Col span={24}>
        <Title level={3} className='section-title'>Fencing Service</Title>
        <Divider />
        <Paragraph style={{marginBottom: "0"}}>
          At BSKLandscapes, we understand the importance of privacy, security, and aesthetic appeal when it comes to fencing. Our fencing service offers a wide range of options to enhance your property, provide boundaries, and create a welcoming atmosphere.
        </Paragraph>
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Custom Fencing Solutions</Title>
            </div>
            <Paragraph>
              We offer custom fencing solutions tailored to your specific needs and preferences. Whether you're looking for privacy fencing, security fencing, or decorative fencing, our expert team will work with you to design and install a fence that complements your property's style and meets your functional requirements.
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Durable and High-Quality Materials</Title>
            </div>
            <Paragraph>
              We source and use only durable and high-quality materials for our fencing projects. From traditional wood fencing to low-maintenance vinyl and metal fencing, we ensure that your fence is built to last and withstand the elements. Our materials are chosen for their durability, longevity, and ability to enhance the overall appearance of your property.
            </Paragraph>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Professional Installation</Title>
            </div>
            <Paragraph>
              Our experienced installation team ensures that your fence is installed with precision and attention to detail. We take pride in our workmanship and strive to deliver a fence that is not only visually appealing but also sturdy and reliable. We adhere to industry standards and best practices to ensure a seamless and durable fence installation.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          Whether you're looking to enhance the security of your property, create a safe play area for children and pets, or add an elegant touch to your landscape, our fencing service has got you covered. We combine functionality, aesthetics, and quality craftsmanship to deliver exceptional fencing solutions.
        </Paragraph>
        <Paragraph>
          Contact us today to discuss your fencing project and let us help you choose and install the perfect fence for your property.
        </Paragraph>
      </Col>
    </Row>
  );
}

export default FencingService;
