import React from 'react'
import { Col, Row, Card, Typography, Image, Divider } from 'antd'
import { PhoneFilled, MailOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, PushpinOutlined, PhoneOutlined, EllipsisOutlined } from '@ant-design/icons';
import LogoLink1 from "../../assets/logo1link.jpg"
import { useMedia } from 'react-media';

const { Title, Paragraph } = Typography

function FooterComponent() {
    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    return (
        <>
            <Row style={{ width: "100%", justifyContent: "center", padding: "24px", textAlign: "center", backgroundColor: "rgb(0, 117, 85)" }} >
                <Col xs={24} sm={6} lg={8} xl={8} style={{ marginBottom: "5px" }}>
                    <EllipsisOutlined style={{ color: "greenyellow" }} /><Title level={4} style={{ paddingTop: "0", marginTop: "0", color: "white", fontWeight: "bold" }}>Socials</Title>
                    <Row style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>
                                <a href="https://facebook.com" style={{ color: "white", textDecoration: "underline" }}>
                                    Facebook
                                </a>
                            </span>
                        </Col>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>
                                <a href="https://facebook.com" style={{ color: "white", textDecoration: "underline" }}>
                                    Instagram
                                </a>
                            </span>
                        </Col>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>
                                <a href="https://facebook.com" style={{ color: "white", textDecoration: "underline" }}>
                                    Twitter
                                </a>
                            </span>
                        </Col>
                    </Row>

                </Col>
                <Col xs={24} sm={6} lg={8} xl={8} style={{ marginBottom: "5px" }}>
                    <EllipsisOutlined style={{ color: "greenyellow" }} /><Title level={4} style={{ paddingTop: "0", marginTop: "0", color: "white" }}>About</Title>
                    <Row style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
                        {(matches.small || matches.medium) && <PushpinOutlined style={{ fontSize: "18px", color: "white", padding: "5px" }} />}
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {matches.large && <PushpinOutlined style={{ fontSize: "18px", color: "white", padding: "5px" }} />}
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>Wharf Street. Chadderton. Oldham. Lancashire. OL9 7PF</span>
                        </Col>
                        {(matches.small || matches.medium) && <PhoneOutlined style={{ fontSize: "18px", color: "white", padding: "5px" }} />}
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {matches.large && <PhoneOutlined style={{ fontSize: "18px", color: "white", padding: "5px" }} />}
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>+44 0161 633 3553</span>
                        </Col>
                        {(matches.small || matches.medium) && <MailOutlined style={{ fontSize: "18px", color: "white", padding: "5px" }} />}

                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {matches.large && <MailOutlined style={{ fontSize: "18px", color: "white", padding: "5px" }} />}
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>brian.knaggs@bsklandscapes.co.uk</span>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={6} lg={8} xl={8} style={{ marginBottom: "5px" }}>
                    <EllipsisOutlined style={{ color: "greenyellow" }} /><Title level={4} style={{ paddingTop: "0", marginTop: "0", color: "white" }}>Links</Title>
                    <Row style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>
                                <a href="https://facebook.com" style={{ color: "white", textDecoration: "underline" }}>
                                    Emergency Medical Fire and Rescue Solutions
                                </a>
                            </span>
                        </Col>
                        <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ alignSelf: "center", color: "white", fontSize: "16px", padding: "5px" }}>
                                <a href="https://facebook.com" style={{ color: "white", textDecoration: "underline" }}>
                                    Wicksteed Playscapes
                                </a>
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ width: "100%", justifyContent: "center", padding: "0 0 12px 0" }}>
                <Col xs={24} sm={12} md={8} xl={8} span={5} style={{ color: "white", textAlign: "center" }}>
                    <Row style={{ width: "100%", justifyContent: "center", padding: "0" }}>
                        <Col xs={20} sm={24} lg={24} xl={24} style={{ padding: "0" }}>
                            <Divider style={{ color: "white", background: "white", margin: "0 0 12px 0" }} />
                        </Col>
                        <Col xs={24}>
                            <Paragraph style={{ color: "white", marginBottom: "0" }}>Copyright BSK Landscapes 2023©. All Rights Reserved.</Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FooterComponent