import React from 'react'
import { Col, Row, Card, Typography, Image } from 'antd'
import {useMedia} from "react-media"
import { ArrowRightOutlined } from '@ant-design/icons';
import Decking from "../../assets/decking.jpeg"
import Garden from "../../assets/playareas.jpeg"
import Landscapes from "../../assets/landscapes.jpeg"
import Gripping from "../../assets/gripping.jpeg"
import SnowClearance from "../../assets/snowclearance.jpeg"
import {TbFence} from "react-icons/tb"
import {GiKidSlide, GiTruck, GiTrade} from "react-icons/gi"
import {MdLandscape,MdDeck} from "react-icons/md"
import "./Services.css"
import {FaSnowplow} from "react-icons/fa"
import Fencing from "../../assets/fencing.jpeg"
const { Title, Paragraph } = Typography

function Services() {

    const GLOBAL_MEDIA_QUERIES = {
        extraSmall: "(min-width: 200px) and (max-width: 400px)",
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    return (
    <>
    <Row gutter={[8, 8]}>
            <Col xs={24}>
                <Title level={2} style={{ margin: "24px 24px 0 24px", textAlign: "center" }} className='section-title'>Services</Title>
            </Col>
            <Row gutter={!(matches.small || matches.extraSmall) && [0, 0]} style={{width: "100%", justifyContent: "center"}}> 
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: matches.large ? "end" : "center"}} className='service-row'>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <MdDeck style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Decking</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can design and install private and commercial decking to the highest standard. We have a specialist team who have an excellent
                                    reputation for the standard of work. We only use high standard decking to ensure top standard results.</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: matches.large ? "start" : "center"}} className='service-row'>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <GiKidSlide style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Play Areas</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can design and install private and commercial decking to the highest standard. We have a specialist team who have an excellent
                                    reputation for the standard of work. We only use high standard decking to ensure top standard results.</Paragraph>
                        </Col>
                    </Row>             
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: matches.large ? "end" : "center"}}  className='service-row'>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <MdLandscape style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Landscaping</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can design and install private and commercial decking to the highest standard. We have a specialist team who have an excellent
                                    reputation for the standard of work. We only use high standard decking to ensure top standard results.</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: matches.large ? "start" : "center"}} className='service-row'>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <GiTruck style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Gritting & Salt Spreading Service</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can design and install private and commercial decking to the highest standard. We have a specialist team who have an excellent
                                    reputation for the standard of work. We only use high standard decking to ensure top standard results.</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: matches.large ? "end" : "center"}} className='service-row'>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <FaSnowplow style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Snow Clearance</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can design and install private and commercial decking to the highest standard. We have a specialist team who have an excellent
                                    reputation for the standard of work. We only use high standard decking to ensure top standard results.</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: matches.large ? "start" : "center"}} className='service-row'>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <TbFence style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Fencing</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can design and install private and commercial decking to the highest standard. We have a specialist team who have an excellent
                                    reputation for the standard of work. We only use high standard decking to ensure top standard results.</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0 10px"}}>
                    <Row style={{justifyContent: "center"}} className='service-row'>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{alignSelf: "center", textAlign: "end", padding: "15px"}} flex="none" className='service-icon'>
                            <GiTrade style={{fontSize: "72px"}}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{padding: "15px"}} flex="auto">
                                <Title level={4}>Land Reclamation</Title>
                                <Paragraph style={{textAlign: "justify"}}> We can complete all types of land Reclaimation , from full excavations, drainage, concreting, levelling  brickwork and surfacing. 
                                We have a full range of machinery to complete major contracts , all our employees are fully trained in plant use.</Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
                </Row>
            </>
            )
}

            export default Services