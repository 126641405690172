import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumb, Col, Layout, Menu, Row, theme } from 'antd';
import { useMedia } from 'react-media';
import { BrowserRouter as Router, Routes, Route, Switch, Link } from 'react-router-dom';


import { EditOutlined, EllipsisOutlined, SettingOutlined, ArrowDownOutlined } from '@ant-design/icons';

import Navbar from './Components/Navbar/Navbar';

import "./App.css"
import About from './Components/About/About';
import Skills from './Components/HappyClients/Happy';
import Satisfied from './Components/SatisfiedCustomers/Satisfied';
import Services from './Components/Services/Services';
import FooterComponent from './Components/Footer/Footer';
import { ContactEmail } from './Components/ContactForm/ContactEmail';
import DeckingService from './Components/Services/Tab/DeckingService';
import LandscapingService from './Components/Services/Tab/LandscapingService';
import PlayAreasService from './Components/Services/Tab/PlayAreaService';
import LandReclamationService from './Components/Services/Tab/ReclamationService';
import SnowClearanceService from './Components/Services/Tab/SnowClearanceService';
import FencingService from './Components/Services/Tab/FencingService';
import GrittingService from './Components/Services/Tab/GrittingService';
import Hiring from './Components/Hiring/Hiring';
const { Header, Content, Footer } = Layout;

const ServiceRoutes = [
  {
    route: "decking",
    component: <DeckingService/>
  },
  {
    route: "play-areas",
    component: <PlayAreasService/>
  },
  {
    route: "landscaping",
    component: <LandscapingService/>
  },
  {
    route: "land-reclamation",
    component: <LandReclamationService/>
  },
  {
    route: "gritting-and-salt-spreading",
    component: <GrittingService/>
  },
  {
    route: "snow-clearance",
    component: <SnowClearanceService/>
  },
  {
    route: "fencing",
    component: <FencingService/>
  }
]

function App() {
  return (
    <Router>
      <Layout className="layout" style={{ background: "white", minHeight: "100vh" }}>
        <Header style={{ paddingInline: "0" }}>
          <Navbar />
        </Header>

        <Content>
          <Routes>
            <Route path="/" element={<>
            <Row justify={"space-evenly"} style={{ padding: "48px 24px", background: "rgb(0, 117, 85)", borderBottomLeftRadius: "50% 20px", borderBottomRightRadius: "50% 20px" }}>
              <About />
            </Row>
            <Row justify={"space-evenly"} style={{ padding: "24px" }}>
              <Skills />
            </Row>
            <Row justify={"space-evenly"} style={{ padding: "24px" }}>
              <Satisfied />
            </Row>
            <Row justify={"space-evenly"} style={{ padding: "24px" }}>
              <Services />
            </Row>
            <Row id="contactScroll" justify={"space-evenly"} style={{ padding: "24px", width: "100%" }}>
              <ContactEmail />
            </Row>
            </>}>
            </Route>
            {ServiceRoutes.map(item => {
              return (
                <Route path={item.route} element={item.component}></Route>
              )
            })}
            <Route path="/hiring" element={<Hiring/>}></Route>
        </Routes>
        </Content>
        <Footer style={{ backgroundColor: "rgb(0, 117, 85)", padding: "0" }}>
          <FooterComponent />
        </Footer>
      </Layout>
    </Router>
  );
}

export default App;
