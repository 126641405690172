import React, { useEffect, useState, useRef } from 'react'
import { Col, Row, Menu, Drawer, Button, Divider, Typography, Statistic, Timeline } from 'antd';
import { LikeFilled, ClockCircleOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import "./Satisfied.css"


export default function Satisfied() {
    const [activeUsers, setActiveUsers] = useState(0)
    const [isVisible, setIsVisible] = useState(false);
    const componentRef = useRef(null);
    useEffect(() => {
        const checkVisibility = () => {
            if (componentRef.current) {
              const rect = componentRef.current.getBoundingClientRect();
              const windowHeight = window.innerHeight || document.documentElement.clientHeight;
              const isVisible = rect.top <= windowHeight;
              setIsVisible(isVisible);
            }
          };
      
          const handleScroll = () => {
            checkVisibility();
          };
      
          window.addEventListener('scroll', handleScroll);
          checkVisibility();

        if (activeUsers === 4673 || !isVisible) {
            return
        }
        let countInterval = setInterval(() => {
            setActiveUsers(activeUsers >= 4500 ? activeUsers + 1 : activeUsers + 10)
        }, 1)

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(countInterval);
        }

    }, [activeUsers, componentRef, isVisible])
    return (
        <div ref={componentRef}>
            {isVisible && (<>
            <Title level={2} style={{textAlign: "center", marginBottom: "0"}} className='section-title'>Satisfied Clients</Title>
            <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col span={24} style={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
                    <span style={{ alignSelf: "center", padding: "10px" }}><LikeFilled style={{ fontSize: "300%", color: "#659A62", alignSelf: "center" }} /></span>
                    <span style={{ alignSelf: "center", fontSize: "200px", padding: "10px" }}><Statistic style={{ fontSize: "200px" }} value={activeUsers} /></span>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} xl={12} style={{ alignSelf: "center" }}>
                    <Paragraph style={{ textAlign: "justify" }}>
                        Our landscape company has witnessed an impressive surge in customers, capitalizing on the soaring demand for our top-notch services. This notable increase in clientele is a
                        testament to the quality and value we offer in transforming outdoor spaces.
                    </Paragraph>
                </Col>
            </Row>
            <Row style={{ width: "100%", justifyContent: "center" }} gutter={[40, 40]}>
            <Col span={12} xs={24} sm={24} md={12} xl={12} style={{ alignSelf: "center" }}>
                    <Timeline
                        mode="alternate"
                        items={[
                            {
                                children: 'BSK Landscapes have been established for over 25 years',
                            },
                            {
                                children: 'Evaluate the market trends and customer demands to identify potential service gaps',
                                color: 'green',
                            },
                            {
                                children: ` Develop a comprehensive marketing strategy to increase brand awareness and attract more customers. Utilize online platforms, social media, targeted advertising, and collaborations with complementary businesses to reach a larger audience.`,
                            },
                            {
                                color: 'green',
                                children: 'Focus on building long-term relationships with existing customers. Offer loyalty programs, referral incentives, and personalized follow-ups to encourage repeat business and word-of-mouth recommendations.',
                            },
                            {
                                children: 'Invest in Employee Training and Development: Foster a skilled and knowledgeable workforce by investing in ongoing training programs.',
                            },
                            {
                                dot: (
                                    <ClockCircleOutlined
                                        style={{
                                            fontSize: '16px',
                                        }}
                                    />
                                ),
                                children: 'Expansion Plans',
                            },
                        ]}
                    />
                </Col>
            </Row></>)}
        </div>
    )
}
