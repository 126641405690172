import React from 'react'
import "./Hiring.css"

export default function Hiring() {
    return (
        <div class="container-hiring">
            <h1 className='section-title'>We Are Hiring</h1>
            <p className='desc'>Welcome to BSK Landscapes, where we are dedicated to building a skilled and passionate team in various branches of our company. If you are seeking a fulfilling career in the fields of Landscaping, Decking, Play Areas, Gritting &amp; Salt Spreading Service, Snow Clearance, Fencing, or Land Reclamation, you have come to the right place.</p>

            <h2>Available Positions</h2>
            <ul class="job-list">
                <li>
                    <span class="job-title">Landscaping</span>
                    <p class="job-description">Join our Landscaping team and unleash your creativity in transforming outdoor spaces into stunning works of art. From designing and implementing intricate garden layouts to selecting and nurturing a wide array of plants, your expertise will contribute to creating captivating landscapes that leave a lasting impression.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Landscaping Job Application">Apply Now</a>
                </li>
                <li>
                    <span class="job-title">Decking</span>
                    <p class="job-description">In our Decking department, you will have the opportunity to showcase your carpentry skills and craftsmanship. Build beautiful decks and outdoor structures that seamlessly blend functionality and aesthetics, providing our clients with a perfect place to relax, entertain, and enjoy the outdoors.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Decking Job Application">Apply Now</a>
                </li>
                <li>
                    <span class="job-title">Play Areas</span>
                    <p class="job-description">Be part of our Play Areas team and make a positive impact on the lives of children. Construct safe and exciting play spaces that encourage imagination, physical activity, and social interaction. Your work will contribute to the joy and development of young minds, creating memorable play experiences.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Play Areas Job Application">Apply Now</a>
                </li>
                <li>
                    <span class="job-title">Gritting &amp; Salt Spreading Service</span>
                    <p class="job-description">Winter brings unique challenges, but with our Gritting &amp; Salt Spreading Service, you will be at the forefront of ensuring safety and accessibility. Join our team to help keep roads, parking lots, and pathways clear of ice and snow, enabling smooth and secure travel for all.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Gritting & Salt Spreading Job Application">Apply Now</a>
                </li>
                <li>
                    <span class="job-title">Snow Clearance</span>
                    <p class="job-description">Winter warriors are always in demand, and with our Snow Clearance team, you can turn snowy landscapes into manageable and safe environments. Embrace the excitement and camaraderie as you assist in clearing snow from various locations, ensuring smooth operations and minimising disruptions caused by winter weather.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Snow Clearance Job Application">Apply Now</a>
                </li>
                <li>
                    <span class="job-title">Fencing</span>
                    <p class="job-description">Precision and durability are key in our Fencing department. Join us to construct sturdy and visually appealing fences that provide security, privacy, and elegance to our clients&#39; properties. Showcase your attention to detail and expertise in delivering fencing solutions that exceed expectations.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Fencing Job Application">Apply Now</a>
                </li>
                <li>
                    <span class="job-title">Land Reclamation</span>
                    <p class="job-description">Make a lasting impact on the environment with our Land Reclamation team. Restore and rehabilitate areas affected by industrial activities, transforming them into vibrant and sustainable ecosystems. Your work will contribute to the preservation and revitalisation of natural habitats for future generations to enjoy.</p>
                    <a class="cta-button" href="mailto:j.knaggs@bsklandscapes.co.uk?subject=Land Reclamation Job Application">Apply Now</a>
                </li>
            </ul>
            <p className='desc'>At BSK Landscapes, we value professionalism, teamwork, and a passion for excellence. We offer a
                supportive work environment, opportunities for growth and development, and competitive
                compensation packages. Join us and become part of a dynamic team that takes pride in delivering
                exceptional results and exceeding client expectations.</p>

            <p className='desc'>
                If you are ready to embark on an exciting career journey with us, we invite you to explore our current
                job openings and submit your application. We look forward to reviewing your qualifications and
                welcoming you to the BSK Landscapes family. Together, let&#39;s build a better future.
            </p>

        </div>
    )
}
