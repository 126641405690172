import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Col, Typography, Row } from "antd"
import { PhoneOutlined } from '@ant-design/icons';
import "./ContactEmail.css"

const { Title, Paragraph } = Typography

export function ContactEmail() {
    const [state, handleSubmit] = useForm("mayzgrdy");
    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }
    return (
        <Col span={24}>
            <Title level={2} style={{ textAlign: "center" }} className='section-title'>Contact</Title>
            <Paragraph style={{ textAlign: "center" }}>
                <span style={{ alignSelf: "center", color: "black", fontSize: "16px", padding: "5px" }} >
                    Call us to discuss your specific requirements
                </span>
                <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <PhoneOutlined style={{ fontSize: "18px", color: "black", padding: "5px", strokeWidth: "20" }} />
                    <span style={{ alignSelf: "center", color: "black", fontSize: "16px", padding: "5px" }}>+44 0161 633 3553</span>
                </Col>
                <div strong style={{ fontSize: "24px", fontWeight: "normal" }}>OR</div>
                <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ alignSelf: "center", color: "black", fontSize: "16px", padding: "5px" }}>
                        Write us an email and we'll get back at you!
                    </span>
                </Col>
            </Paragraph>
            <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col span={24} style={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
                    <div class="email-form-container" onSubmit={handleSubmit}>
                        <form class="email-form">
                            <label for="name">Name:</label>
                            <input type="text" id="name" name="name" required />

                            <label for="email">Email:</label>
                            <input type="text" id="email" name="email" required />

                            <label for="message">Message:</label>
                            <textarea id="message" name="message" rows="4" required></textarea>

                            <button type="submit">
                                Submit
                            </button>
                        </form>
                    </div>
                </Col>
            </Row>
        </Col>
    );
}