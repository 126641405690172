import { Col, Row, Card, Typography } from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import { CheckCircleOutlined, BulbOutlined, ClockCircleOutlined, SmileOutlined, ArrowDownOutlined } from '@ant-design/icons';
import "./Happy.css"
const {Title} = Typography

export default function Skills() {
    const [isVisible, setIsVisible] = useState([false, false, false, false]);
    const cardRefs = useRef([]);

    useEffect(() => {
        const checkCardVisibility = () => {
          cardRefs.current.forEach((cardRef, index) => {
            if (cardRef && cardRef.getBoundingClientRect().top <= window.innerHeight * 0.75) {
              setIsVisible((prevState) => {
                if (!prevState[index]) {
                  const updatedState = [...prevState];
                  updatedState[index] = true;
                  return updatedState;
                }
                return prevState;
              });
            }
          });
        };
    
        const handleScroll = () => {
          checkCardVisibility();
        };
    
        window.addEventListener('scroll', handleScroll);
        checkCardVisibility();
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Title level={2} ref={(ref) => (cardRefs.current[0] = ref)} style={{margin: "24px", textAlign: "center"}} className={`section-title ${isVisible[0] ? 'fade-in' : ''} title-card one`}>What makes us stand out?</Title>
    <Row ref={(ref) => (cardRefs.current[0] = ref)} className={`card ${isVisible[0] ? 'fade-in' : ''}`} style={{width: "100%", justifyContent: "center"}} gutter={[40, 40]}>
        <Col xs={24} sm={12} md={12} xl={6} span={5}>
            <Card className={`card-skills one ${isVisible[0] ? 'fade-in' : ''}`} ref={(ref) => (cardRefs.current[0] = ref)} style={{ textAlign: "center", height: "100%" }}>
                <div>
                    <CheckCircleOutlined style={{fontSize: '300%'}}/>
                </div>
                <Title level={4}>Expert Craftsmanship</Title>
                <p>Our skilled artisans sculpt landscapes with precision and care, creating captivating outdoor spaces that surpass expectations. Trust in our expertise to bring your vision to life.</p>
            </Card>
        </Col>
        <Col xs={24} sm={12} md={12} xl={6} span={5}>
            <Card style={{ textAlign: "center", height: "100%" }} className={`card-skills two ${isVisible[0] ? 'fade-in' : ''}`} ref={(ref) => (cardRefs.current[0] = ref)}>
                <div>
                    <BulbOutlined style={{fontSize: '300%'}}/>
                </div>
                <Title level={4}>Stunning Design</Title>
                <p>Our creative visionaries craft stunning landscapes that captivate the senses. From harmonious color palettes to striking focal points, we weave artistry into every detail, transforming outdoor spaces into breathtaking works of living art.</p>
            </Card>
        </Col>
        <Col xs={24} sm={12} md={12} xl={6} span={5}>
            <Card style={{ textAlign: "center", height: "100%" }} className={`card-skills three ${isVisible[0] ? 'fade-in' : ''}`} ref={(ref) => (cardRefs.current[0] = ref)}>
                <div>
                    <ClockCircleOutlined style={{fontSize: '300%'}}/>
                </div>
                <Title level={4}>Reliable Service</Title>
                <p>With unwavering commitment, we provide reliable service that exceeds expectations. From prompt project completion to transparent communication, we ensure a seamless experience, earning your trust as your go-to partner for all landscaping needs.</p>
            </Card>
        </Col>
        <Col xs={24} sm={12} md={12} xl={6} span={5}>
            <Card style={{ textAlign: "center", height: "100%"}} className={`card-skills four ${isVisible[0] ? 'fade-in' : ''}`} ref={(ref) => (cardRefs.current[0] = ref)}>
                <div>
                    <SmileOutlined style={{fontSize: '300%'}}/>
                </div>
                <Title level={4}>Customer Satisfaction</Title>
                <p>Our unwavering commitment to your satisfaction drives us to deliver personalized solutions, exceptional craftsmanship, and a smooth, hassle-free experience.</p>
            </Card>
        </Col>
    </Row>
    </>
  )
}
