import React from 'react'
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function LandscapingService() {
  return (
    <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
      <Col span={24}>
        <Title level={3} className='section-title'>Landscaping Service</Title>
        <Divider />
        <Paragraph style={{marginBottom: "0"}}>
          At BSKLandscapes, we understand the importance of a well-designed and functional outdoor living space. Our landscaping service is tailored to meet your specific requirements, ensuring a result that exceeds your expectations.
        </Paragraph>
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Custom Landscape Design</Title>
            </div>
            <Paragraph>
              Our expert team of landscape designers will create a custom design that transforms your outdoor space into a stunning and functional landscape. We consider your preferences, site characteristics, and environmental factors to deliver a landscape design that reflects your style and maximizes the beauty of your property.
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Hardscape and Softscape Elements</Title>
            </div>
            <Paragraph>
              We specialize in creating harmonious landscapes by combining hardscape and softscape elements. From patios, walkways, and retaining walls to planting beds, trees, and flowers, we carefully integrate various features to enhance the aesthetics and functionality of your outdoor space.
            </Paragraph>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }}/>
              <Title level={4} style={{ marginBottom: "revert" }}>Professional Installation and Maintenance</Title>
            </div>
            <Paragraph>
              Our skilled team handles the entire process of installing your landscape, ensuring precise execution and attention to detail. We use high-quality materials and follow industry best practices to create landscapes that stand the test of time. Additionally, we provide comprehensive maintenance services to keep your landscape looking its best throughout the year.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          Whether you desire a serene and peaceful garden, a vibrant and colorful oasis, or a functional outdoor living space, our team will work closely with you to create a landscape that fulfills your dreams. We take pride in our craftsmanship and attention to detail, ensuring that your landscape becomes a true masterpiece.
        </Paragraph>
        <Paragraph>
          Contact us today to discuss your landscaping project and let us bring the beauty of nature to your doorstep.
        </Paragraph>
      </Col>
    </Row>
  )
}

export default LandscapingService