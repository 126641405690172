import { AppstoreOutlined, MailOutlined, SettingOutlined, MenuOutlined, HomeOutlined, HomeFilled, FileImageFilled, ToolFilled, InfoCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { MdWork } from "react-icons/md"
import { Col, Row, Menu, Drawer, Button, Divider, Typography } from 'antd';
import { Outlet, Link } from "react-router-dom";
import { useMedia } from 'react-media';
import Logo from "../../assets/2.png"
import "./Navbar.css"

import Scroll from 'react-scroll';

const { Text, Title } = Typography;


const Navbar = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    return (
        <>
            <Row style={{ alignItems: "center", backgroundColor: "#007555", padding: "0 10px" }} justify="space-between">
                <Col xs={6}>
                    <div key="logo" style={{ width: '200px', justifyContent: 'left', display: "flex" }}>
                        <img src={Logo} style={{ width: "100px", padding: "5px" }} alt="BSKLandscapesLogo" />
                    </div>
                </Col>
                <Col xs={12}>
                    <Menu mode="horizontal" style={{ display: 'flex', backgroundColor: "#007555", alignContent: "center", boxShadow: matches.large && "rgba(0, 0, 0, 0.1) 0px 4px 20px 1px", justifyContent: matches.large ? "center" : "end" }}>
                        {(matches.small || matches.medium) &&
                            <div style={{ padding: "0 16px", cursor: "pointer", color: "white" }} onClick={() => setDrawerVisible(true)}>
                                <MenuOutlined />
                            </div>}
                        {matches.large &&
                            <>
                                <Menu.Item style={{ color: "white", fontSize: "18px" }} key="mail" icon={<HomeFilled style={{ color: "white", fontSize: "18px" }} />}>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="app" style={{ color: "white", fontSize: "18px" }} icon={<FileImageFilled style={{ color: "white", fontSize: "18px" }} />}>
                                    Gallery
                                </Menu.Item>

                                <Menu.SubMenu style={{ color: "white", fontSize: "18px" }} key="SubMenu" icon={<ToolFilled style={{ color: "white", fontSize: "18px" }} />} title="Services">
                                    <Menu.Item key="service:1">
                                        <Link to="decking">
                                            Decking
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service:2">
                                        <Link to="landscaping">
                                            Landscaping
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service:3">
                                        <Link to="land-reclamation">
                                            Land Reclamation
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service:4">
                                        <Link to="snow-clearance">
                                            Snow Clearance
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service:5">
                                        <Link to="fencing">
                                            Fencing
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service:6">
                                        <Link to="play-areas">
                                            Play Areas
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="service:7">
                                        <Link to="gritting-and-salt-spreading">
                                            Gritting & Salt Spreading
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                <Menu.Item key="hiring" style={{ color: "white", fontSize: "18px" }} icon={<MdWork style={{ color: "white", fontSize: "18px" }} />}>
                                    <Link to="/hiring">
                                        We're Hiring!
                                    </Link>
                                </Menu.Item>
                            </>}
                    </Menu>
                    <Drawer
                        className='navbar'
                        placement="right"
                        closable={false}
                        title={<div span={24} style={{ display: "flex", justifyContent: 'center', display: "flex", textAlign: "center" }}>
                            <img src={Logo} style={{ width: "100px", padding: "5px" }} alt="BSKLandscapesLogo" />
                        </div>}
                        footer={<Button className='navbar close-button' onClick={() => setDrawerVisible(false)} block>
                            Close
                        </Button>}
                        open={drawerVisible}
                        onClose={toggleDrawer}
                    >
                        <Row gutter={[8, 8]} justify="center">
                            <Col span={24}>
                                <Link to="/">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Home
                                    </Button>
                                </Link>
                            </Col>
                            <Col span={24}>
                                <Link to="/gallery">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Gallery
                                    </Button>
                                </Link>
                            </Col>
                            <Col span={24}>
                                <Link to="/hiring">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        We're Hiring!
                                    </Button>
                                </Link>
                            </Col>
                            <Title level={4} style={{ textAlign: "center", padding: "0", margin: "5px", fontWeight: "lighter" }}>Services</Title>
                            <Col span={24}>
                                <Link to="/decking">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Decking
                                    </Button>
                                </Link>
                            </Col>
                            <Col span={24}>
                                <Link to="/landscaping">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Landscaping
                                    </Button>
                                </Link>

                            </Col>
                            <Col span={24}>
                                <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                    <Link to="/land-reclamation">
                                        Land Reclamation
                                    </Link>
                                </Button>
                            </Col>
                            <Col span={24}>
                                <Link to="/snow-clearance">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Snow Clearance
                                    </Button>
                                </Link>

                            </Col>
                            <Col span={24}>
                                <Link to="/fencing">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Fencing
                                    </Button>
                                </Link>
                            </Col>

                            <Col span={24}>
                                <Link to="/play-areas">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Play Areas
                                    </Button>
                                </Link>
                            </Col>
                            <Col span={24}>
                                <Link to="/gritting-and-salt-spreading">
                                    <Button className='navbar theme-button' block onClick={() => setDrawerVisible(false)}>
                                        Gritting & Salt Spreading
                                    </Button>
                                </Link>
                            </Col>
                            <Title level={4} style={{ textAlign: "center", padding: "0", margin: "5px", fontWeight: "lighter" }}>Contact</Title>

                            <Row style={{ width: "100%" }} wrap={true}>

                                <Col xs={24} sm={8} md={8} xl={8}>
                                    <Button className='navbar theme-button' onClick={() => { window.open('tel:0161 633 3553', "_self") }} block>
                                        Call
                                    </Button>

                                </Col>
                                <Col xs={24} sm={8} md={8} xl={8}>
                                    <Button className='navbar theme-button' onClick={() => { window.open('mailto:brian.knaggs@bsklandscapes.co.uk', "_self") }} block>
                                        E-mail
                                    </Button>
                                </Col>
                                <Col xs={24} sm={8} md={8} xl={8}>
                                    <Button className='navbar theme-button' onClick={() => { window.open('https://goo.gl/maps/NzYsr4xL7fCMmZfP8', "_blank") }} block>
                                        Google Maps
                                    </Button>
                                </Col>
                            </Row>

                        </Row>

                    </Drawer>
                </Col>
                {matches.large &&
                    <Col xs={6} style={{ textAlign: "end" }}>
                        <Button className='navbar theme-button' style={{border: "0"}} shape="round" icon={<InfoCircleFilled />} size={"default"} onClick={() => {
                            const elementToScroll = document.getElementById("contactScroll")
                            window.scrollTo({ top: elementToScroll.offsetTop, block: 'center' });

                        }}>
                            Contact
                        </Button>
                    </Col>
                }            </Row >
        </>
    );
};
export default Navbar;