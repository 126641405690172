import { EditOutlined, EllipsisOutlined, SettingOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Image, Row, Typography } from 'antd';
import {useEffect, useRef, useState} from "react"
import {useMedia} from 'react-media';
import Hero from "./landscape_hero.jpg"
import "./About.css"
const { Meta } = Card;
const { Title, Paragraph } = Typography;


function About() {
    const image = useRef(null);
    const [tilt, setTilt] = useState({ x: 0, y: 0 });

    const GLOBAL_MEDIA_QUERIES = {
        extraSmall: "(min-width: 200px) and (max-width: 400px)",
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    useEffect(() => {
        const container = image.current;
        const maxTilt = 20;
        if (!container) {
          return
        }

        const handleMouseMove = (e) => {
          const containerRect = container.getBoundingClientRect();
          const x = (e.clientX - containerRect.left - containerRect.width / 2) / 10;
          const y = (containerRect.top + containerRect.height / 2 - e.clientY) / 10;
          setTilt({
            x: Math.min(Math.max(x, -maxTilt), maxTilt),
            y: Math.min(Math.max(y, -maxTilt), maxTilt)
          });
        };
    
        const handleMouseLeave = () => {
          setTilt({ x: 0, y: 0 });
        };
    
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseleave', handleMouseLeave);
    
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
          window.removeEventListener('mouseleave', handleMouseLeave);
        };
      }, []);
  return (
    <>
        <Col span={8} xs={24} sm={24} md={24} xl={8} style={{ alignSelf: "center"}}>
            <Row>
                <Col span={24} >
                    <Paragraph style={{color: 'rgb(147 200 144)', fontSize: "18px"}}>
                        Discover the Artistry of Our Premier Landscape Company
                    </Paragraph>
                    <Title class="about-header-display" level={2} style={{marginTop: "0", color: "white"}}>
                        Creating Nature's Masterpieces, One Yard at a Time
                    </Title>
                    <Paragraph italic style={{color: "white", fontSize: "18px"}}>
                        Discover the difference of B S K Landscapes—where design, craftsmanship, and maintenance intertwine to create outdoor spaces that captivate and inspire.
                    </Paragraph>
                </Col>
            </Row>
        </Col>
        {matches.large && <Col span={8} style={{ alignSelf: "center" }} className='about-img-container' ref={image}>
            <img className='about-img' style={{ transform: `rotateY(${tilt.x}deg) rotateX(${tilt.y}deg)` }} src="https://lh3.googleusercontent.com/p/AF1QipNXSnUjvYBgjZCT9ZkBD-7CBt2qAs82nWEDqj-f=s1360-w1360-h1020"/>
        </Col>}
    </>
  )
}

export default About