import React from 'react';
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function DeckingService() {
    return (
        <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
            <Col span={24}>
                <Title level={3} className='section-title'>Decking Service</Title>
                <Divider />
                <Paragraph style={{marginBottom: "0"}}>
                At BSKLandscapes, we understand the importance of a well-designed and functional outdoor living space. Our decking service is tailored to meet your specific requirements, ensuring a result that exceeds your expectations.
            </Paragraph>
                <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>

                    <Col span={24} md={12} lg={8}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
                            <Title level={4} style={{ marginBottom: "revert" }}>Custom Deck Design</Title>
                        </div>
                        <Paragraph>
                            Our skilled team of designers will work closely with you to create a custom deck design that perfectly suits your outdoor space. We consider your preferences, style, and functional requirements to ensure your new deck enhances your landscaping and meets your needs.
                        </Paragraph>
                    </Col>
                    <Col span={24} md={12} lg={8}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
                            <Title level={4} style={{ marginBottom: "revert" }}>High-Quality Materials</Title>
                        </div>
                        <Paragraph>
                            We use only the finest quality materials for our decking projects. From durable hardwoods to low-maintenance composite decking, we source materials that are built to withstand the elements while maintaining their beauty and longevity.
                        </Paragraph>
                    </Col>
                    <Col span={24} md={24} lg={8}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
                            <Title level={4} style={{ marginBottom: "revert" }}>Expert Installation</Title>
                        </div>
                        <Paragraph>
                            Our experienced installation team ensures that your deck is constructed with precision and attention to detail. We take pride in our workmanship and strive to deliver a deck that is not only visually appealing but also structurally sound, providing you with a safe and enjoyable outdoor space.
                        </Paragraph>
                    </Col>
                </Row>
                <Paragraph>
                    Whether you envision a spacious deck for entertaining guests, a cozy corner for relaxation, or a multi-level deck with integrated landscaping features, our team will bring your vision to life. We combine creativity, expertise, and quality craftsmanship to deliver exceptional decking solutions.
                </Paragraph>
                <Paragraph>
                    Contact us today to discuss your decking project and discover how we can transform your outdoor space into a stunning retreat.
                </Paragraph>
            </Col>

        </Row>
    )
}

export default DeckingService