import React from 'react';
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function SnowClearanceService() {
  return (
    <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
      <Col span={24}>
        <Title level={3} className='section-title'>Snow Clearance Service</Title>
        <Divider />
        <Paragraph style={{marginBottom: "0"}}>
          At BSKLandscapes, we understand the challenges posed by snowfall during the winter season. Our snow clearance service is designed to keep your property safe and accessible even in the harshest winter conditions. With our professional team and specialized equipment, we ensure efficient and reliable snow removal services.
        </Paragraph>
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Timely and Efficient Snow Removal</Title>
            </div>
            <Paragraph>
              We are committed to providing timely and efficient snow removal services to keep your driveways, walkways, and parking areas clear of snow and ice. Our team closely monitors weather conditions to ensure proactive planning and prompt response, so you can safely navigate your property without any disruptions caused by snow accumulation.
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Specialized Equipment</Title>
            </div>
            <Paragraph>
              We utilize advanced snow removal equipment, including plows, blowers, and de-icing agents, to efficiently clear snow from your property. Our equipment is carefully maintained and operated by skilled professionals who are experienced in handling various snow removal challenges. We ensure that your property is cleared effectively and with minimal disruption.
            </Paragraph>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Safe and Environmentally-Friendly Practices</Title>
            </div>
            <Paragraph>
              We prioritize the safety of both our clients and the environment. Our snow removal practices adhere to industry standards and regulations, ensuring safe and responsible snow clearance. We utilize environmentally-friendly de-icing agents and minimize the use of chemicals to protect your landscaping and surrounding ecosystems.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          With our snow clearance service, you can rely on us to handle all your winter maintenance needs. Whether it's residential or commercial properties, we are equipped to effectively clear snow, ensuring safe access for you, your family, employees, and visitors.
        </Paragraph>
        <Paragraph>
          Contact us today to discuss your snow clearance requirements and let us take care of your winter maintenance, so you can enjoy a hassle-free winter season.
        </Paragraph>
      </Col>
    </Row>
  );
}

export default SnowClearanceService;
