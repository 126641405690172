import React from 'react';
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function PlayAreasService() {
  return (
    <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
      <Col span={24}>
        <Title level={3} className='section-title'>Play Areas Service</Title>
        <Divider />
        <Paragraph style={{marginBottom: "0"}}>
          At BSKLandscapes, we understand the importance of creating safe and enjoyable play areas for children. Our play areas service is designed to provide engaging and well-designed spaces that promote active play and imagination, while ensuring the highest standards of safety.
        </Paragraph>
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Custom Play Area Design</Title>
            </div>
            <Paragraph>
              Our expert team of designers specializes in creating custom play area designs that cater to the unique needs and preferences of your children. We take into account factors such as age group, available space, and desired play activities to create a design that is both engaging and safe. From interactive play structures to themed play zones, we bring creativity and imagination to every project.
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Safe and Durable Materials</Title>
            </div>
            <Paragraph>
              Safety is our top priority when it comes to play areas. We only use high-quality, child-safe materials that are built to withstand rigorous play and outdoor conditions. From impact-absorbing surfaces to non-toxic and weather-resistant equipment, we ensure that every element of the play area is designed with the utmost consideration for the safety and well-being of your children.
            </Paragraph>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Professional Installation and Maintenance</Title>
            </div>
            <Paragraph>
              Our skilled team of installers will handle the complete installation of your play area, ensuring proper assembly and adherence to safety guidelines. We pay attention to every detail to ensure that the play area is securely installed and ready for hours of fun. Additionally, we offer regular maintenance services to keep the play area in excellent condition, including inspections, repairs, and thorough cleaning.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          We believe that play areas should not only provide entertainment but also stimulate creativity, social interaction, and physical development. Our goal is to create play areas that spark joy and leave lasting memories for children and families.
        </Paragraph>
        <Paragraph>
          Contact us today to discuss your play area project and let us create a safe and exciting space where children can explore, play, and grow.
        </Paragraph>
      </Col>
    </Row>
  );
}

export default PlayAreasService;