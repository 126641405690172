import React from 'react';
import { Typography, Divider, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function GrittingService() {
  return (
    <Row justify={"space-evenly"} style={{ padding: "0 24px" }}>
      <Col span={24}>
        <Title level={3} className='section-title'>Gritting & Salt Spreading Service</Title>
        <Divider />
        <Paragraph style={{marginBottom: "0"}}>
          At BSKLandscapes, we understand the challenges posed by icy and snowy conditions during the winter months. Our gritting and salt spreading service provides a reliable and effective solution to keep your premises safe and accessible.
        </Paragraph>
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Professional Gritting Team</Title>
            </div>
            <Paragraph>
              Our experienced and trained gritting team is equipped with the knowledge and expertise to handle all types of winter weather conditions. We carefully monitor weather forecasts and promptly respond to ensure timely gritting and salt spreading, minimizing the risk of accidents and maintaining safe pathways.
            </Paragraph>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Efficient Gritting Process</Title>
            </div>
            <Paragraph>
              We use specialized equipment and follow proven gritting processes to ensure efficient and thorough coverage. Our team is trained in the proper application of grit and salt, taking into account different surface types and environmental considerations. This attention to detail guarantees maximum effectiveness in melting snow and ice.
            </Paragraph>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckOutlined style={{ fontSize: '20px', marginRight: '8px', color: "#659A62" }} />
              <Title level={4} style={{ marginBottom: "revert" }}>Environmentally-Friendly Solutions</Title>
            </div>
            <Paragraph>
              We are committed to using environmentally-friendly gritting and salt spreading solutions. Our team carefully selects products that are effective in melting ice and snow while minimizing harm to the environment, vegetation, and surrounding areas. We prioritize the safety of both people and the planet in our winter maintenance practices.
            </Paragraph>
          </Col>
        </Row>
        <Paragraph>
          With our gritting and salt spreading service, you can ensure that your premises, whether residential or commercial, remain safe and accessible during winter weather conditions. Our proactive approach and attention to detail will give you peace of mind and allow you to focus on your daily activities.
        </Paragraph>
        <Paragraph>
          Contact us today to discuss your gritting and salt spreading needs and let our team provide you with reliable winter maintenance solutions.
        </Paragraph>
      </Col>
    </Row>
  );
}

export default GrittingService;
